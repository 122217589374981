a {
  font-size: 2vh;
  margin-bottom: 0;
}
.letter-spacing {
  &-1 {
    letter-spacing: 1px;
  }
  &-2 {
    letter-spacing: 2px;
  }
  &-3 {
    letter-spacing: 3px;
  }
}
