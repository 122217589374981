.bg-full{
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  overflow:hidden;
  z-index:-1;
}
.bg-gradient{
  background: #FFFFFF;
  background: linear-gradient(180deg, #153549 0%, #0F2028 100%);
}
